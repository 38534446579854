import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const useAuthCheck = (id) => {
    const navigate = useNavigate();
    const params = useParams();
    const paramValue = params[id];
 
 
    useEffect(() => {
        // Function to verify the token
        const verifyToken = async () => {      
            const savedToken = localStorage.getItem('auth_token');
            // If no token or an invalid token is found, redirect to login
            if (!savedToken || !isValidToken(savedToken, id)) {
               
                navigate('/');
            } else {
                 
          
            }
        };

        verifyToken();
    }, [navigate, id]);
};
function isValidToken(token, id)  {        
    var tokenPayload = decodeToken(token);

    var auth_EmployeeID = tokenPayload.sub;
  
    return !!token && auth_EmployeeID == id; // Return true if token exists and EmployeeIDs Match, false otherwise

};
function decodeToken(token) {
try {
    
    const parts = token.split('.');
    const header = JSON.parse(atob(parts[0]));
    const payload = JSON.parse(atob(parts[1]));
    
    const headerDecoded = JSON.parse(urlBase64Decode(parts[0]));
    const payloadDecoded = JSON.parse(urlBase64Decode(parts[1]));
    return payloadDecoded;
} catch (error) {
    return null;
}
}
function urlBase64Decode(str) {
    // Convert URL-safe base64 to regular base64.
    let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    // Pad with '=' characters if the base64 string is not a multiple of 4.
    let padding = '='.repeat((4 - (base64.length % 4)) % 4);
    // Replace incorrect padding.
    base64 = base64.concat(padding);
    return atob(base64);
}
    
  

export default useAuthCheck;
