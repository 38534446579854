import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import './App.css';
import { HashRouter as Router, Route, Routes, Navigate, useParams, Outlet } from 'react-router-dom';
import EmployeeSelectionOrder from './EmployeeSelectionOrder';
import ManagersView from './ManagersView';
import Login from './login';
import { useNavigate } from 'react-router-dom'; 
import useAuthCheck from './useAuthCheck';

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const [currentEmployeeID, setCurrentEmployeeID] = useState(null);
  
   
    const checkManagerPermissions = (managersEmployeeID, employeeID) => {
        const jsonData = localStorage.getItem('managersEmployees');
        const responseData = JSON.parse(jsonData);

        if (Array.isArray(responseData) && responseData.length > 0) {
            // Find the employee in the manager's employees array
            const exists = responseData.some(employee => String(employee.employeeID) === String(employeeID));
            return exists;           
        }
        return false; 
       
    };
    function EmployeeSelectionOrderWrapper(props) {
        const { employeeID } = useParams();
        // If the employeeID from the URL doesn't match the authenticated user's ID, redirect them    

        
        if (employeeID != currentEmployeeID ) {

            return <Navigate to="/" />;
        }       

        return <EmployeeSelectionOrder {...props} />;
    }
    function ManagerEmployeeSelectionOrderWrapper() {
        const { managersEmployeeID, employeeID } = useParams();
        // Logic to check if managersEmployeeID matches the authenticated manager's ID and if they have the necessary permissions
        if (managersEmployeeID !== currentEmployeeID || !checkManagerPermissions(managersEmployeeID, employeeID)) {

            return <Navigate to="/" />;
        }

        return <EmployeeSelectionOrder employeeID={employeeID} />;
    }
    function ProtectedRoute({ children }) {
        console.log("authenticated at route ", authenticated);
        return authenticated ? children : <Navigate to="/" />;
    }
    
    const PrivateRoute = () => {
        
        const id = localStorage.getItem('currentEmployeeID');
        useAuthCheck(id); // Check the auth state
        const token = localStorage.getItem('auth_token');
        return token ? <Outlet /> : <Navigate to="/" replace />;
      };
      
    return (
        <Router>
            <Routes>
         
                <Route path="/" element={<Login />} />
                <Route element={<PrivateRoute/>}>
                    
                    <Route path="/EmployeeSelection/:programYear/:employeeID" element={<EmployeeSelectionOrder />} />
                    <Route path="/EmployeeSelection/:programYear/:managersEmployeeID/:employeeID" element={<EmployeeSelectionOrder />} />
                    <Route path="/ManagersView/:programYear/:managersEmployeeID" element={<ManagersView />} />
                </Route>
                <Route path="*" element={<Navigate to="/Login" replace   />} />
            </Routes>         
        </Router>
  );
}

export default App;
