import React, { useEffect, useRef, useState } from 'react';
 
// Inside your component

import logo from './images/CW_Logo-retina.png';
import './App.css';
import { FaTrash, FaTrashAlt, FaRegTrashCan } from 'react-icons/fa';
import { FaArrowDown } from 'react-icons/fa';
import {FiArrowDownCircle  } from 'react-icons/fi';
import { PiArrowCircleDownThin, PiCaretCircleDoubleDownThin, PiCaretCircleDownThin, PiCaretDoubleDownThin, PiArrowFatLinesDownThin, PiArrowSquareDownThin } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; // This line might differ based on the library
import useAuthCheck from './useAuthCheck';



function EmployeeSelectionOrder() {
    
    const navigate = useNavigate();
 
    const [employee, setEmployee] = useState();
    const [totalPointsUsed, setTotalPointsUsed] = useState(0);
    const [managerName, setManagerName] = useState('The Manager Name');
    const [managerEmployeeID, setManagerEmployeeID] = useState(0);
    const [isManager, setIsManager] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedOrderItems, setSelectedOrderItems] = useState([]);
    const [availableOrderItems, setAvailableOrderItems] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState({});    
    const [backendUrl, setBackendUrl] = useState('https://cw-eip.azurewebsites.net');
    const {programYear, managersEmployeeID, employeeID } = useParams();
    const location = useLocation();
    const isFromManagerView = location.pathname.includes('/ManagersView/');
    const isEmployeeSelectionPage = location.pathname.includes('/EmployeeSelection/');
    const [currentEmployeeID, setCurrentEmployeeID] = useState(null); // or managersEmployeeID
    const showNavbar = isManager || location.pathname.includes("/EmployeeSelection/:programYear/:managersEmployeeID");
    const [isOrderSummaryOpen, setOrderSummaryOpen] = useState(true); // default to open
    const [isOrderComplete, setIsOrderComplete] = useState(false);   
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [showScrollToBottom, setShowScrollToBottom] = useState(false);
    const orderSummaryRef = useRef(null);
    const bottomRef = useRef(null);
    const [allowUpdate, setAllowUpdate] = useState(false);
    const [cutOffDate, setCutOffDate] = useState(new Date("1/1/1999"));  
    const [orderDate, setOrderDate] = useState(new Date("1/1/1999"));  
    const [headerPadding, setHeaderPadding] = useState('50px'); // Default padding
    const [headerPaddingOffset, setHeaderPaddingOffset] = useState('0px'); // Default padding
    const [mgrSet, setMgrSet] = useState(false);
    const [deadlineMessage, setDeadlineMessage] = useState('');
    const [isEmployee, setIsEmployee] = useState(false);
    const checkBottomVisibility = () => {       
        if (bottomRef.current && isOrderSummaryOpen && selectedOrderItems.length >= 9) {
          const rect = bottomRef.current.getBoundingClientRect();
          setShowScrollToBottom(rect.bottom > window.innerHeight);
        } else {
            setShowScrollToBottom(false);
        }
      };
      useEffect(() => {

        // Here you can perform actions that depend on the updated value of allowUpdate
    }, [allowUpdate]);
    
    useEffect(() => {
        const currentDate = new Date();
        currentDate.setHours(23, 59, 59, 0);
        cutOffDate.setHours(23, 59, 59, 0);
   

        if (currentDate <= cutOffDate) {
            setAllowUpdate(true);
           if (isEmployee) {
                setDeadlineMessage('All changes must be completed by ' + cutOffDate.toLocaleDateString('en-US') + ' at 11:59 PM');
           } else { 
                setDeadlineMessage('Manager changes must be completed by ' + cutOffDate.toLocaleDateString('en-US') + ' at 11:59 PM');
           }
        } else {
            setAllowUpdate(false);    
             
            setDeadlineMessage('The deadline to order has passed!');
        }        

    }, [cutOffDate]);

    useEffect(() => {
        window.addEventListener('scroll', checkBottomVisibility);
        return () => window.removeEventListener('scroll', checkBottomVisibility);
    }, []);
    
    const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {        
    if (isOrderSummaryOpen && selectedOrderItems.length >= 9) {        
        checkBottomVisibility();
    } else {
        setShowScrollToBottom(false);
    }
    }, [isOrderSummaryOpen, selectedOrderItems.length]);

    // Determine which ID to check based on the presence of managersEmployeeID
    const idToCheck = managersEmployeeID || employeeID;

    // Call the useAuthCheck
    useAuthCheck(idToCheck);

      
    const updateEmployeeSelections = async (item) => {
        try {

                await axios.post('https://cw-eip.azurewebsites.net/employee/updateSelections', {
                EmployeeID: employeeID,
                SwagItemID: item.swagItemID,
                SizeID: selectedSizes[item.swagItemID],
                ItemQuantity: item.quantity,
                PointsUsed: item.pointsUsed,
                IsManager: isManager,
                IsAdmin: isAdmin
            });
            await fetchSelectedOrderItems();
  
            resetAddedItemQuantity(item.swagItemID);
            setIsOrderComplete(false);
            setEmployee(prevEmployee => ({
                ...prevEmployee,
                orderComplete: false
              }));
               
        } catch (error) {
            console.error("Error updating selection:", error);
            if (error.response) {
                console.error("Server Response:", error.response.data);
            }
            resetAddedItemQuantity(item.swagItemID);
        }
    };
    const fetchSelectedOrderItems = async () => {
        const employeeId = employee.employeeID;
        
        await axios.get(`${backendUrl}/employee/${employeeID}/selectedOrderItems/${programYear}`)
            .then(response => {               
                const orderItems = response.data;
                setSelectedOrderItems(orderItems); 
         
                if (orderItems.length > 0 && orderItems[0].orderComplete) {
                    setIsOrderComplete(true);                  
                }
            }).catch(error => {
                console.error("Error fetching selected order items:", error);
                
            });
    };
    const fetchAvailableOrderItems = async () => {
        const employeeId = employee.employeeID;
        await axios.get(`${backendUrl}/employee/${employeeID}/availableOrderItems/${programYear}`)
            .then(response => {
                setAvailableOrderItems(response.data);
            });

};
    const calculateTotalPointsUsed = () => {
        const total = selectedOrderItems.reduce((acc, item) => acc + item.pointsUsed, 0);
        setTotalPointsUsed(total);
    };
    const resetAddedItemQuantity = (swagItemID) => {
        setAvailableOrderItems(prevItems => {
            const newItems = [...prevItems];
            const itemIndex = newItems.findIndex(i => i.swagItemID === swagItemID);
            if (itemIndex !== -1) {
                newItems[itemIndex].quantity = 0;
                newItems[itemIndex].pointsUsed = 0; // Also reset the points used for this item

            }
            return newItems;
        });
    };    
    const handleCompleteOrder = async () => {
        // Process the complete order logic here
       
        await axios.post('https://cw-eip.azurewebsites.net/employee/updateSelections', {
            EmployeeID: employeeID,
            SwagItemID: 99999,
            SizeID: 99999,
            ItemQuantity: 99999,
            PointsUsed: 99999,
            IsManager: isManager,
            IsAdmin: isAdmin

        });
        setIsOrderComplete(true);
        setEmployee(prevEmployee => ({
            ...prevEmployee,
            orderComplete: true
          }));
          
        console.log('Order is being processed...');
       
    };      
    const handleDelete = async (item) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        if (isConfirmed) {
            try {
                await axios.post('https://cw-eip.azurewebsites.net/employee/updateSelections', {
                    EmployeeID: employeeID,
                    SwagItemID: item.swagItemID,
                    SizeID: item.sizeID,
                    ItemQuantity: 0,
                    PointsUsed: item.pointsUsed,
                    IsManager: isManager,
                    IsAdmin: isAdmin
                });

               
                const updatedItems = selectedOrderItems.filter(i => {
                    return !(i.swagItemID === item.swagItemID && i.sizeID === item.sizeID);
                  });
                setSelectedOrderItems(updatedItems);
                calculateTotalPointsUsed();
                fetchAvailableOrderItems();
                setIsOrderComplete(false);
                setEmployee(prevEmployee => ({
                    ...prevEmployee,
                    orderComplete: false
                  }));
               
            } catch (error) {
                console.error("Error updating selection:", error);
            }
        }
    }; 
    useEffect(() => {
        if (employee) {
            const pointsRemaining = employee.maxPoints - totalPointsUsed;
            setAvailableOrderItems(prevItems => {
                return prevItems.map(item => {
                    return {
                        ...item,
                        rowEnabled: item.pointGroup <= pointsRemaining,
                        max: pointsRemaining
                    };
                });
            });
        }
    }, [totalPointsUsed, employee]);
    useEffect(() => {
        // This function will update the selected sizes only if they haven't been set yet.
        const updateSelectedSizes = (currentSizes, items) => {
            const updatedSizes = { ...currentSizes };
    
            items.forEach(item => {
                // Only set the default size if it hasn't been set yet.
                if (updatedSizes[item.swagItemID] === undefined) {
                    updatedSizes[item.swagItemID] = item.sizeID;
                }
            });
    
            return updatedSizes;
        };
    
        setSelectedSizes(currentSizes => updateSelectedSizes(currentSizes, availableOrderItems));
    }, [availableOrderItems]);    
    useEffect(() => {
        calculateTotalPointsUsed();
    }, [selectedOrderItems]);
    useEffect(() => {         
        
        const token = localStorage.getItem('auth_token'); // Retrieve the token from storage
        const isMgr = localStorage.getItem('isManager');        

        var useEmployeeCutOff = false;
        if (!(managersEmployeeID)) {
            setManagerEmployeeID(employeeID);           
            useEmployeeCutOff = true;
            setIsEmployee(true);
        }
        else {
            setManagerEmployeeID(managersEmployeeID);            
            useEmployeeCutOff = false;
            setIsEmployee(false);
        }
       
        if (isMgr == 'true') {                  
           
            setIsManager(true);
            const managerFirstName = localStorage.getItem('firstName');
            const managerLastName = localStorage.getItem('lastName');
            setManagerName(managerFirstName + ' ' + managerLastName);
           
        }
                

        axios.get(`${backendUrl}/employee/${employeeID}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
            setEmployee(response.data[0]);
            if (useEmployeeCutOff == true) {

                setCutOffDate(new Date(response.data[0].cutOffDate)) ;
            }
            else {
                const isAdminTemp = localStorage.getItem('isAdmin');
                if (isAdminTemp == 'true'){
                    setIsAdmin(true);
                }
 
                if (isAdminTemp == 'true') {
                    setCutOffDate(new Date(response.data[0].orderDate)) ;
                }
                else {
                    setCutOffDate(new Date(response.data[0].finalCutOffDate))  ; 
                }
                    
            }
            
        })

        .catch(error => {
            // If unauthorized or token is invalid, redirect to homepage
            if (error.response && error.response.status === 401) {
                navigate('/');
            }
        });

        axios.get(`${backendUrl}/employee/${employeeID}/selectedOrderItems/${programYear}`)
            .then(response => {
                const orderItems = response.data;            
                setSelectedOrderItems(response.data);               
                if (orderItems.length > 0 && orderItems[0].orderComplete) {
                    setIsOrderComplete(true);                  
                }               
                const total = response.data.reduce((acc, item) => acc + item.pointsUsed, 0);
                setTotalPointsUsed(total);                            
            });

        axios.get(`${backendUrl}/employee/${employeeID}/availableOrderItems/${programYear}`)
            .then(response => {
                setAvailableOrderItems(response.data);                 
            }); 
        const position = window.pageYOffset
        if (showNavbar == false) {
            setHeaderPadding('0px'); 
            setHeaderPaddingOffset('35px');
        }
        else if (position <= 20) {     
            setHeaderPadding('20px'); // Less padding
            setHeaderPaddingOffset('0px');
        } else {
            setHeaderPadding('54px'); // More padding 
            setHeaderPaddingOffset('0px');
        }  
    }, []);

    const isMgr = localStorage.getItem('isManager'); // Retrieve the token from storage
    
    useEffect(() => {
    const handleScroll = () => {
      // Determine scroll position
        const position = window.pageYOffset;

        // Set padding based on the scroll position
        if (showNavbar == false) {
            setHeaderPadding('0px'); 
            setHeaderPaddingOffset('35px');
        }
        else if (position <= 20) {     
            setHeaderPadding('20px'); // Less padding
            setHeaderPaddingOffset('0px');
        } else {
            setHeaderPadding('54px'); // More padding 
            setHeaderPaddingOffset('0px');
        }

    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);  

   
    // Function to open the lightbox with the selected image
    const openLightbox = (imagePath) => {
      setCurrentImage(imagePath);
      setLightboxOpen(true);
    };
      

    return (

        <div className="pageMargin">
            {showNavbar &&
                <Navbar bg="light" expand="lg" sticky="top">
                    <Container>
                        <Navbar.Toggle aria-controls="navbar-nav" />
                        <Navbar.Collapse id="navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link as={Link} to={`/ManagersView/${programYear}/${managerEmployeeID}`}>
                                    Managers View
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to={`/ManagersView/${programYear}/${managerEmployeeID}`}
                                    disabled={isFromManagerView}
                                    style={{ pointerEvents: isFromManagerView ? 'none' : 'auto', color: isFromManagerView ? 'gray' : 'black' }}
                                >
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to={`/EmployeeSelection/${programYear}/${managerEmployeeID}`}
                                    disabled={isEmployeeSelectionPage}
                                    style={{ pointerEvents: isEmployeeSelectionPage ? 'none' : 'auto', color: isEmployeeSelectionPage ? 'gray' : 'black' }}
                                >
                                    Employee View ({managerName})
                                </Nav.Link>
                                {/* Add more Nav.Link components as needed */}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            }
            <div className="header" style={{ position: 'sticky', top: headerPadding  }}>
                <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'Left' }}>
                        <img src={logo} alt="Company Logo" style={{ maxHeight: 140, marginLeft: '10px', marginTop: headerPaddingOffset }} />
                        
                    </div>
                    {employee && (
                        <div className="employeeInfo" style={{ fontWeight: 'bold', marginTop: headerPaddingOffset }}>
                            <p style={{ margin: '4px 0' }}>Employee # {employeeID}</p>
                            <p style={{ margin: '4px 0' }}>{employee.firstName} {employee.lastName}</p>                          
                            <p style={{ margin: '4px 0' }}>Max Points: {employee.maxPoints}</p>
                            <p style={{ margin: '4px 0' }}>Points Remaining: {employee.maxPoints - totalPointsUsed}</p>
                            {employee.orderComplete && <p style={{ margin: '4px 0', color: 'green' }}>Order Complete</p>}
                        </div>
                    )}
                </header>
                {/* Divider */}
                <hr  style={{ margin: '0', padding: '0' }}/>
                
                {showScrollToBottom && isOrderSummaryOpen && (
                    <div className="scroll-to-bottom" onClick={scrollToBottom} style={{ bottom: showNavbar ? '52px' : '35px' }}>
                        <PiCaretCircleDoubleDownThin size="3rem" style={{ color:'gray' }}/>
                    </div>
                )}
                {/* Display order form options */}               
                <div>
                    <h2 style={{ textAlign: 'center' , marginTop: '-10px' , padding: '-10px'}}>  
                    <style>
                            {`
                                @keyframes blink {
                                    0% { opacity: 1; }
                                    50% { opacity: 0; }
                                    100% { opacity: 1; }
                                }
                                .blink {
                                    animation: blink 2s linear infinite;
                                }
                            `}
                        </style>
                        <small className="blink" style={{ fontWeight: 'bold', fontSize: '10px', color: allowUpdate ? 'blue': 'red', marginBottom: '0px', padding: '0px' }}>{deadlineMessage}</small>
                        <button 
                        onClick={() => setOrderSummaryOpen(!isOrderSummaryOpen)} 
                        style={{ fontSize: '12px', marginTop: '0px'  }}  
                        >
                        {isOrderSummaryOpen ? "Hide - Order Summary" : "Show - Order Summary"}
                        </button>
                    </h2>
                    {isOrderSummaryOpen && selectedOrderItems.length > 0 && (
                        <>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                <th style={{ textAlign: 'left' }}>Qty</th>
                                    <th style={{ textAlign: 'left' }}>Description</th>
                                    <th style={{ textAlign: 'left' }}>Size</th>
                                    <th style={{ textAlign: 'left' }}>Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOrderItems.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: 'left' }}>{item.quantity}</td>
                                        <td style={{ textAlign: 'left' }}>{item.orderFormDescription}</td>
                                        <td style={{ textAlign: 'left' }}>{item.size}</td>
                                        <td style={{ textAlign: 'left' }}>{item.pointsUsed}</td>
                                        <td>
                                            <button style={{ width: '35px'}} onClick={() => handleDelete(item)} disabled={!allowUpdate}>
                                                <FaTrashAlt />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                         {/* 'Complete Order' Button */}
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                        <button 
                            onClick={handleCompleteOrder} 
                            style={{ 
                            fontSize: '12px',
                            padding: '10px 20px',
                            backgroundColor: isOrderComplete ? '#ccc' : '#4CAF50', // Greyed out if complete
                            color: isOrderComplete ? '#666' : 'white', // Darker text if disabled
                            color: 'white',
                            border: 'none',
                            borderRadius: '1px',
                            cursor: 'pointer',
                            // Other styling as needed
                            }}
                        >
                            {isOrderComplete ? "Order Complete" : "Submit Order"}
                            
                        </button>
                        </div>
                        
                        <style jsx>{`
                                    .scroll-to-bottom {
                                    position: fixed;
                                    bottom: 20px;
                                    left: 50%; 
                                    transform: translateX(-50%);                                     
                                    cursor: pointer;
                                   
                                    }
                                `}</style>
                        </>
                    )}      
                </div>                
            </div>


            <div style={{ paddingTop: '10px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                        {availableOrderItems.map((item, index) => (
                            <tr
                                className="table-row"
                                key={index}
                                style={{
                                    opacity: item.rowEnabled ? 1 : 0.5,
                                    borderBottom: '1px solid #ddd' // add a bottom border
                                }}
                            >
                                <td style={{ backgroundColor: '#ffffff', width: '120px' }}><img src={process.env.PUBLIC_URL + item.imagePath} alt={item.orderFormDescription} style={{ maxWidth: '120px' }} onClick={() => openLightbox(process.env.PUBLIC_URL + item.imagePath)} /></td>
                                <td>
                                    <div style={{ fontWeight: 'bold', marginBottom: '20px', marginLeft: '10px'}}>{item.orderFormDescription}</div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', alignItems: 'left', marginLeft: '10px' }}>
                                            <label style={{ marginRight: '5px'  }} htmlFor={`quantity${index}`}> Quantity: </label>
                                            <input
                                                type="number"
                                                id={`quantity${index}`}
                                                min="0"
                                                max={item.maxQuantity}
                                                value={item.quantity}
                                                disabled={!item.rowEnabled}
                                                style={{ width: '50px', height: '28px', padding: '2px 4px'  }}
                                                onChange={event => {
                                                    let quantity = parseInt(event.target.value);
                                                    if (quantity > item.maxQuantity) {
                                                        quantity = item.maxQuantity;
                                                    }
                                                    const newPointsUsed = quantity * item.pointGroup;
                                                    setAvailableOrderItems(prevItems => {
                                                        const newItems = [...prevItems];
                                                        newItems[index] = { ...newItems[index], quantity: quantity, pointsUsed: newPointsUsed };
                                                        return newItems;
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', marginLeft: '10px' }}>
                                            <label style={{ marginRight: '5px'}} htmlFor={`size${index}`}>Size: </label>
                                            <select
                                                id={`size${index}`}
                                                disabled={!item.rowEnabled}
                                                defaultValue={item.sizeID} // Set default value based on defaultSizeID
                                                style={{ height: '28px', padding: '2px 4px' }}
                                                onChange={event => {
                                                    setSelectedSizes(prevSizes => ({
                                                        ...prevSizes,
                                                        [item.swagItemID]: event.target.value
                                                    }));
                                                }}
                                            >
                                                {item.sizes && item.sizes.map((size, i) => (
                                                    <option key={i} value={size.sizeID}>{size.sizeName}</option>
                                                ))}
                                            </select>

                                        </div>
                                       

                                    </div>
                                   
                                    <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ marginLeft: '10px' }}>
                                            <button style={{ marginTop: '10px', marginBottom: '20px', width: '50px', height: '28px', padding: '2px 4px' }} disabled={!allowUpdate || item.quantity === 0} onClick={() => updateEmployeeSelections(item)}>Add</button>
                                        </div>
                                        <div style={{ textAlign: 'right' , marginRight: '25px'}}>
                                            Points Used: {item.pointsUsed}
                                        </div>
                                    </td>
                                </td>

                                
                            </tr>
                        ))}
                    </tbody>
                </table>
                {lightboxOpen && (
                    <Lightbox
                    mainSrc={currentImage}
                    onCloseRequest={() => setLightboxOpen(false)}
                    // ... other props and configurations for lightbox ...
                    />
                )}
            </div>
            {/* Divider */}
            <hr />
            <div ref={bottomRef} style={{ height: '1px' }} />
            <div style={{ textAlign: 'right' }}>
                Total Points Used: {totalPointsUsed}
            </div>
          
        </div>

    );
}

export default EmployeeSelectionOrder;
